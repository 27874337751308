<template>
  <show-suspendedbox ref="suspendedBoxRef"></show-suspendedbox>
  <div class="login">
      <div id="logo"><img :style="{width: store.getters['server/getLogoWidth']}" src="/tarkan/assets/custom/logoWhite.png"></div>

      <div id="login-form">
        <div style="text-align: center;" v-if="$route.name==='Share'">
          {{KT('login.charge')}}<br><br>
            <div style="margin-left: 10%;">
            <el-progress :percentage="50" status="warning" :indeterminate="true" />
            </div>
        </div>
        <template v-else>
          <h2 class="login-title">{{KT('login.welcome')}}</h2>
          <div class="input-container">
            <div class="label">{{KT('login.username')}}</div>
            <div class="input-wrapper">
              <i class="fas fa-user input-icon"></i>
              <input 
                ref="userinput" 
                v-model="username" 
                @keydown.enter="passinput.focus()" 
                :placeholder="KT('login.your_email')" 
                class="custom-input"
                type="text"
              />
            </div>
          </div>
          <div class="input-container">
            <div class="label">{{KT('login.password')}}</div>
            <div class="input-wrapper">
              <i class="fas fa-lock input-icon"></i>
              <input 
                id="password-field"
                ref="passinput" 
                v-model="password" 
                @keydown.enter="doLogin()" 
                type="password"
                :placeholder="KT('login.your_password')"
                class="custom-input"
              />
              <button 
                type="button" 
                @click="togglePasswordVisibility()" 
                class="toggle-password"
              >
                <i 
                  class="fas" 
                  :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                ></i>
              </button>
            </div>
          </div>
          
          <div class="login-options">
            <div class="remember-me">
              <label style="display: flex; align-items: center; cursor: pointer;">
                <input 
                  type="checkbox"
                  :checked="rememberme"
                  @change="toggleRememberMe"
                  style="margin-right: 5px;"
                />
                <span>{{ KT('rememberme') }}</span>
              </label>
            </div>
            <div 
              class="forgot-password" 
              @click="doPasswordRecovery()"
            >
              {{ KT('login.lost_password') }}
            </div>
          </div>

          <div class="button-container">
            <el-button type="primary" @click="doLogin()" class="login-button">
              {{KT('login.signin')}}
            </el-button>
          </div>
          
          <!-- App Links -->
          <div class="app-links" v-if="getAndroidAppConfig?.enabled || getAppleAppConfig?.enabled">
            <div v-if="getAndroidAppConfig?.enabled" class="app-link">
              <a :href="getAndroidAppConfig?.url || '#'" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-android"></i>
                <span>{{ KT('login.android_app') || 'Android App' }}</span>
              </a>
            </div>
            <div v-if="getAppleAppConfig?.enabled" class="app-link">
              <a :href="getAppleAppConfig?.url || '#'" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-apple"></i>
                <span>{{ KT('login.apple_app') || 'iOS App' }}</span>
              </a>
            </div>
          </div>
          
          <!-- Data Policy Link -->
          <div v-if="getDataPolicyConfig?.enabled" class="data-policy">
            <a :href="getDataPolicyConfig?.url || '#'" target="_blank" rel="noopener noreferrer">
              <i class="fas fa-shield-alt"></i>
              <span>{{ KT('login.data_policy') || 'Política de datos' }}</span>
            </a>
          </div>
          <div class="login-footer">
            <div class="version-info">
              <span class="version-text">{{$t('version')}} {{$t('id')}} @ {{store.state.server?.serverInfo?.version || '-'}}</span>
            </div>
            <div class="whatsapp-support" v-if="getWhatsAppConfig?.enabled">
              <a :href="getWhatsAppConfig?.url || '#'" target="_blank" rel="noopener noreferrer" class="support-link">
                <i class="fab fa-whatsapp"></i>
                <span>{{ $t('support') }}</span>
              </a>
            </div>
          </div>
          
          <!-- Información de depuración eliminada -->
        </template>

       


      </div>

    

  </div>
</template>



<script setup>

import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/switch/style/css'
import 'element-plus/es/components/progress/style/css'
import 'element-plus/es/components/message-box/style/css'

//import {ElInput} from "element-plus/es/components/input";

import {ElProgress} from "element-plus/es/components/progress";
//import {ElSwitch} from "element-plus/es/components/switch";
import {ElButton} from "element-plus/es/components/button";

import {ElMessageBox} from "element-plus/es/components/message-box";

import KT from '../tarkan/func/kt'

import {useRouter,useRoute} from 'vue-router'
import {useStore} from 'vuex';



const store = useStore();

const {push} = useRouter();
const route = useRoute();


import {ref, onMounted, inject, provide, computed, watch} from 'vue';
import ShowSuspendedbox from "@/tarkan/components/views/show-suspendedbox.vue";

const traccar = inject('traccar');
//const flyTo = inject('markerClick');

const userinput = ref(null);
const passinput = ref(null);

const rememberme = ref(false);
const username = ref(route.query.user || '');
const password = ref(route.query.pass || '');
const showPassword = ref(false);

// Propiedades computadas para acceder a la configuración de aplicaciones y política de datos
const getAndroidAppConfig = computed(() => {
  try {
    // Accede a la configuración desde el store o CONFIG global
    // eslint-disable-next-line no-undef
    const config = store.state.server?.labelConf || (typeof window.CONFIG !== 'undefined' ? window.CONFIG : {});
    return config?.androidApp || { enabled: false, url: '' };
  } catch (error) {
    console.error("Error al obtener configuración de Android App:", error);
    return { enabled: false, url: '' };
  }
});

const getAppleAppConfig = computed(() => {
  try {
    // eslint-disable-next-line no-undef
    const config = store.state.server?.labelConf || (typeof window.CONFIG !== 'undefined' ? window.CONFIG : {});
    return config?.appleApp || { enabled: false, url: '' };
  } catch (error) {
    console.error("Error al obtener configuración de Apple App:", error);
    return { enabled: false, url: '' };
  }
});

const getDataPolicyConfig = computed(() => {
  try {
    // eslint-disable-next-line no-undef
    const config = store.state.server?.labelConf || (typeof window.CONFIG !== 'undefined' ? window.CONFIG : {});
    return config?.dataPolicy || { enabled: false, url: '' };
  } catch (error) {
    console.error("Error al obtener configuración de Data Policy:", error);
    return { enabled: false, url: '' };
  }
});

const getWhatsAppConfig = computed(() => {
  try {
    // eslint-disable-next-line no-undef
    const config = store.state.server?.labelConf || (typeof window.CONFIG !== 'undefined' ? window.CONFIG : {});
    const whatsappNumber = config?.whatsapp || '';
    
    return {
      enabled: !!whatsappNumber, // true si hay número, false si está vacío
      url: whatsappNumber ? 'https://wa.me/' + whatsappNumber : ''
    };
  } catch (error) {
    console.error("Error al obtener configuración de WhatsApp:", error);
    return { enabled: false, url: '' };
  }
});

// Verifica si hay al menos un enlace de aplicación habilitado
//const hasAppLinks = computed(() => {
 // try {
 //   return getAndroidAppConfig.value?.enabled || getAppleAppConfig.value?.enabled || false;
 // } catch (error) {
 //   console.error("Error al verificar enlaces de aplicaciones:", error);
 //   return false;
  //}
//});

const suspendedBoxRef = ref(null);

onMounted(()=>{
  if(route.name==='Share'){
    doLoginWithToken();
  }else {
    userinput.value.focus();
  }
  
  // Asegurar que window.CONFIG existe
  // eslint-disable-next-line no-undef
  if (typeof window.CONFIG === 'undefined') {
    // eslint-disable-next-line no-undef
    window.CONFIG = {};
  }
  
  // Inicializar configuraciones si no existen para asegurar la visibilidad
  // eslint-disable-next-line no-undef
  if (!window.CONFIG.androidApp) {
    // eslint-disable-next-line no-undef
    window.CONFIG.androidApp = { enabled: false, url: '' };
  }
  
  // eslint-disable-next-line no-undef
  if (!window.CONFIG.appleApp) {
    // eslint-disable-next-line no-undef
    window.CONFIG.appleApp = { enabled: false, url: '' };
  }
  
  // eslint-disable-next-line no-undef
  if (!window.CONFIG.dataPolicy) {
    // eslint-disable-next-line no-undef
    window.CONFIG.dataPolicy = { enabled: false, url: '' };
  }

  // Log para depuración
  console.log("App Android:", getAndroidAppConfig.value);
  console.log("App Apple:", getAppleAppConfig.value);
  console.log("Política datos:", getDataPolicyConfig.value);
})

// Observar cambios en el store de configuración
watch(() => store.state.server?.labelConf, (newConfig) => {
  console.log("Configuración actualizada:", newConfig);
  // Comprobar las configuraciones actualizadas
  if (newConfig) {
    console.log("Android App configuración:", newConfig.androidApp || 'No definido');
    console.log("Apple App configuración:", newConfig.appleApp || 'No definido');
    console.log("Política de datos configuración:", newConfig.dataPolicy || 'No definido');
  }
}, { deep: true })


const doPasswordRecovery = () => {
  let user = username.value;

  if (user == '') {
    ElMessageBox.confirm(KT('login.invalid_username'))
        .then(() => {
        })
        .catch(() => {
          // catch error
        })
  }

  if (route.meta.isDriver) {
    user = 'qrcode-' + user;
  }

  store.commit("server/setPage", false);

  traccar.recoverPassword(user).then((r) => {
    console.log(r);

    ElMessageBox.confirm(KT('login.lost_send'))
        .then(() => {
        })
        .catch(() => {
          // catch error
        })

    store.commit("server/setPage", true);
  }).catch((err) => {


    store.commit("server/setPage", true);

    console.log(err);
    //loadingInstance1.close();
    ElMessageBox.confirm(KT(err) || err)
        .then(() => {
        })
        .catch(() => {
          // catch error
        })
  });
}

const togglePasswordVisibility = () => {
  console.log("Toggle password visibility", showPassword.value);
  // Force redraw by directly manipulating DOM
  const inputEl = document.getElementById('password-field');
  if (inputEl) {
    showPassword.value = !showPassword.value;
    inputEl.type = showPassword.value ? 'text' : 'password';
    console.log("Input type changed to:", inputEl.type);
  } else {
    console.log("Password field not found");
    showPassword.value = !showPassword.value;
  }
  console.log("After toggle:", showPassword.value);
}

const toggleRememberMe = (event) => {
  console.log("Toggle remember me", event.target.checked);
  rememberme.value = event.target.checked;
}

const doLoginWithToken = ()=>{
  traccar.login(route.params.token,route.params.token).then((r)=>{
    store.commit('setAuth',r);
    store.dispatch("loadUserData").then(()=> {
      push('/devices/' + r.attributes.deviceId);

      store.commit("devices/setTrail",parseInt(r.attributes.deviceId));
      store.commit("devices/setFollow",parseInt(r.attributes.deviceId));
    });
  }).catch((err)=>{
    ElMessageBox.confirm(KT('INVALID_SHARE') || err)
        .then(() => {
        })
        .catch(() => {
          // catch error
        })
  });
}

const doLogin = ()=>{

  //const loadingInstance1 = ElLoading.service({ fullscreen: true })

  store.commit("server/setPage",false);

  let user = username.value;

  if(route.meta.isDriver){
    user = 'qrcode-'+user;
  }

  traccar.login(user,password.value).then((r)=>{
    console.log(r);

      var regex = /(iPhone|iPad|iPod);[^OS]*OS (\d)/;
      var matches = navigator.userAgent.match(regex);

      if (rememberme.value) {
        window.localStorage.setItem('rememberme', btoa(username.value + "|" + password.value));
      }

      if(route.query.to){
        window.location.href = window.location.protocol + '//' + window.location.host + ''+route.query.to+'?time=' + new Date().getTime();
      }else if(matches){
        if(route.meta.isDriver) {
          window.location.href = window.location.protocol + '//' + window.location.host + '/qr-driver?time=' + new Date().getTime();
        }else {
          window.location.href = window.location.protocol + '//' + window.location.host + '/home?time=' + new Date().getTime();
        }
      }else {

        store.commit('setAuth', r);

        store.dispatch("loadUserData");




        if(route.meta.isDriver) {
          window.location.href = window.location.protocol + '//' + window.location.host + '/qr-driver?time=' + new Date().getTime();
        }else {
          push('/home')
        }
      }
      //loadingInstance1.close();
  }).catch((r)=>{


    store.commit("server/setPage",false);

    console.log(r);
    //loadingInstance1.close();

    if(r.err == 'ACCOUNT_IS_SUSPENDED'){
      suspendedBoxRef.value.showBox(user,password.value,r.trustAvailable,r.invoices);
    }else {
      ElMessageBox.confirm(KT(r.err) || r.err)
          .then(() => {
          })
          .catch(() => {
            // catch error
          })
    }
  });
}

provide('doLogin',doLogin);

</script>

<style scoped>
.login{
  background: url('/tarkan/assets/custom/bg.jpg');
  background-size: cover;

  width: var(--vw,100vw);
  height: var(--vh,100vh);
}

.login:after {
  content: " ";
  position: absolute;
  left: 0;
  top: 0;
  width: var(--vw,100vw);
  height: var(--vh,100vh);
  background: var(--tk-login-filter);
}

#logo{
  position: absolute;
  left: 50%;
  top: 15%;
  width: 30%;
  transform: translate(-50%,-50%);
  z-index: 10;
  transition: width 0.3s;
  text-align: center;
  display: flex;
  justify-content: center;
}

#login-form{
  position: absolute;
  left: 50%;
  top: 55%;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 10px;
  z-index: 10;
  width: 25%;
  transform: translate(-50%,-50%);
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  transition: width 0.3s;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.label{
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.input input{
  width: 100%;
  height: 40px;
  border: silver 1px solid;
  border-radius: 5px;
  outline: none;
  padding: 10px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: all 0.2s;
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  background-color: #f5f7fa;
}

.input-wrapper:hover {
  border-color: #c0c4cc;
}

.input-wrapper:focus-within {
  border-color: var(--el-color-primary);
}

.custom-input {
  flex: 1;
  height: 40px;
  line-height: 40px;
  padding: 0 15px 0 35px;
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  font-size: 14px;
  box-sizing: border-box;
}

.input-wrapper .el-input,
.input-wrapper .el-input__wrapper {
  box-shadow: none !important;
  height: 100%;
  border: none;
  background: none;
}

.input-wrapper .el-input__wrapper {
  padding: 0;
  width: 100%;
}

.input-wrapper .el-input__inner {
  padding-left: 35px;
  height: 100%;
}

.input-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: var(--el-color-primary);
  font-size: 16px;
  z-index: 1;
}

.toggle-password {
  background: none;
  border: none;
  cursor: pointer;
  color: #909399;
  padding: 0 10px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit{
  margin-top: 10px;
  float: right;
}

#copy{
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  padding: 10px;
  z-index: 10001;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
  color: var(--el-color-primary);
  font-weight: 500;
  font-size: 1.8rem;
}

/* Definición de variables CSS adicionales para compatibilidad */
:root {
  --el-color-primary-rgb: 64, 158, 255; /* Color primario en formato RGB para transparencias */
}

.input-container {
  margin-bottom: 15px;
}

.input-icon {
  color: var(--el-color-primary);
  font-size: 16px;
}

.login-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
}

.forgot-password {
  color: var(--el-color-primary);
  font-size: 13px;
  cursor: pointer;
  transition: color 0.3s;
}

.forgot-password:hover {
  color: var(--el-color-primary-light-3);
  text-decoration: underline;
}

.button-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.login-button {
  width: 100%;
  height: 40px;
  font-size: 16px;
  border-radius: 20px;
  transition: all 0.3s;
}

.login-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.app-links {
  display: flex;
  justify-content: center;
  margin-top: 25px;
  gap: 15px;
}

.app-link a, .data-policy a {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--el-color-primary);
  font-size: 11px;
  text-decoration: none;
  transition: all 0.3s;
  background-color: rgba(var(--el-color-primary-rgb), 0.1);
  padding: 5px 10px;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transform: scale(0.85);
}

.app-link a:hover, .data-policy a:hover {
  color: white;
  background-color: var(--el-color-primary);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.app-link i, .data-policy i {
  font-size: 16px;
  margin-right: 8px;
}

.data-policy {
  text-align: center;
  margin-top: 15px;
}

.data-policy a {
  display: inline-flex;
  padding: 6px 12px;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.05);
  color: var(--el-text-color-secondary);
}

/* Animación para el formulario de login */
@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

#login-form {
  animation: fadeIn 0.5s ease-out;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  border: none;
}

/* Estilos para el footer del login */
.login-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  background-color: transparent;
}

.version-info {
  text-align: left;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 6px 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  display: inline-flex;
  align-items: center;
  height: 14px;
}

.version-info:hover {
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.version-text {
  font-size: 9px;
  color: #666;
  opacity: 0.9;
  white-space: nowrap;
  display: inline-block;
}

.whatsapp-support {
  text-align: right;
  margin-left: auto;
}

.support-link {
  display: flex;
  align-items: center;
  color: #25D366;
  font-size: 11px;
  text-decoration: none;
  background-color: rgba(37, 211, 102, 0.1);
  padding: 6px 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  height: 18px;
}

.support-link i {
  font-size: 14px;
  margin-right: 5px;
}

.support-link:hover {
  background-color: rgba(37, 211, 102, 0.2);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  transform: translateY(-1px);
}

@media (max-width: 1000px) {
  #login-form {
    width: 80%;
    top: 60%;
  }
  
  #logo {
    width: 110%; /* Aumentado en 30% desde 70% */
    top: 15%;
  }
  
  .login-title {
    font-size: 1.5rem;
  }
}

@media (max-height: 700px) {
  #logo {
    top: 15%;
  }
  
  #login-form {
    top: 60%;
  }
}

/* Ajuste adicional para dispositivos muy pequeños */
@media (max-width: 480px) {
  #logo {
    width: 120%; /* Asegurar que el logo se vea bien en pantallas muy pequeñas */
    top: 12%;
  }
  
  #login-form {
    width: 90%;
    top: 58%;
  }
}
</style>